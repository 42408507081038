import { makeAutoObservable } from "mobx";
import { RootStore } from "../../store/rootStore";
import dayjs from "dayjs";
import { IYearPlanningArticle, YearPlanningPeriodStatus, YearPlanningTypes } from "./types";
import { approveYearPlanning, getYearPlanning, updateYearPlanning } from "api/incomePlanningYear";
import { showNotificationModal } from "ui-new/alert";
import { showConfirmModal } from "ui-new/alert/confirm";
import { ReactNode } from "react";
import { makePersistable } from "mobx-persist-store";
import { flattenMapByKey } from "utils/functions/flattenByKey";

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

export class YearPlanningStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "YearPlanningStore",
      properties: ["activeTab"],
      storage: window.localStorage,
      expireIn: DAY_IN_MILLISECONDS,
    });
    this.rootStore = rootStore;
  }

  activeTab: YearPlanningTypes = YearPlanningTypes["revenue"];
  setActiveTab = (tab: YearPlanningTypes) => {
    this.activeTab = tab;
  };

  selectedYear: Date = dayjs().startOf("year").toDate();

  setSelectedYear = (date: Date) => {
    this.selectedYear = date;
  };

  yearPlanningRevenue: IYearPlanningArticle[] = [];
  periodStatusRevenue: YearPlanningPeriodStatus | null = null;
  yearPlanningRevenueIsLoading = false;
  yearPlanningExpenses: IYearPlanningArticle[] = [];
  periodStatusExpenses: YearPlanningPeriodStatus | null = null;
  yearPlanningExpensesIsLoading = false;

  setYearPlanningRevenueIsLoading = (isLoading: boolean) => {
    this.yearPlanningRevenueIsLoading = isLoading;
  };

  setYearPlanningExpensesIsLoading = (isLoading: boolean) => {
    this.yearPlanningExpensesIsLoading = isLoading;
  };

  private setYearPlanningIsLoading = (type: keyof typeof YearPlanningTypes, isLoading: boolean) => {
    if (type === "expenses") this.setYearPlanningExpensesIsLoading(isLoading);
    if (type === "revenue") this.setYearPlanningExpensesIsLoading(isLoading);
  };

  setPeriodStatus = (type: keyof typeof YearPlanningTypes, status: YearPlanningPeriodStatus) => {
    if (type === "expenses") this.periodStatusExpenses = status;
    if (type === "revenue") this.periodStatusRevenue = status;
  };

  get currentPeriodStatus() {
    if (this.activeTab === YearPlanningTypes.expenses) return this.periodStatusExpenses;
    if (this.activeTab === YearPlanningTypes.revenue) return this.periodStatusRevenue;
    return null;
  }

  get allCurrentArticlesWithItems() {
    const articles =
      YearPlanningTypes[this.activeTab] === "expenses"
        ? this.yearPlanningExpenses
        : this.yearPlanningRevenue;

    return flattenMapByKey(articles, "items", a => (a.items?.length > 0 ? a.id : null)).filter(
      val => val !== null
    );
  }

  setYearPlanningRevenue = (data: IYearPlanningArticle[]) => {
    this.yearPlanningRevenue = data;
  };

  setYearPlanningExpenses = (data: IYearPlanningArticle[]) => {
    this.yearPlanningExpenses = data;
  };

  setYearPlanning = (type: keyof typeof YearPlanningTypes, data: IYearPlanningArticle[]) => {
    if (type === "expenses") this.setYearPlanningExpenses(data);
    if (type === "revenue") this.setYearPlanningRevenue(data);
  };

  fetchGetYearPlanning = async (type: keyof typeof YearPlanningTypes) => {
    if (!this.selectedYear) {
      showNotificationModal({ title: "Выберите год", type: "failure" });
      return;
    }
    this.setYearPlanningIsLoading(type, true);
    const response = await getYearPlanning(type, dayjs(this.selectedYear).format("YYYY"));
    if (!response) {
      this.setYearPlanningIsLoading(type, false);
      return;
    }
    this.setYearPlanning(type, response.data);
    this.setPeriodStatus(type, response.period_status);
    this.setYearPlanningIsLoading(type, false);
  };

  updateYearPlanningConfirm = async (
    type: keyof typeof YearPlanningTypes,
    values: IYearPlanningArticle[],
    onConfirm?: () => void
  ) => {
    const planningType = type === "revenue" ? "доходов" : "расходов";
    showConfirmModal({
      title: `Сохранить изменения ${planningType} и произвести пересчёт?`,
      confirmButtonLabel: "Сохранить",
      onConfirm: () => this.fetchUpdateYearPlanning(type, values).then(onConfirm),
      minWidth: "370px",
      maxWidth: "370px",
    });
  };

  private fetchUpdateYearPlanning = async (
    type: keyof typeof YearPlanningTypes,
    values: IYearPlanningArticle[]
  ) => {
    if (!this.selectedYear) {
      showNotificationModal({ title: "Выберите год", type: "failure" });
      return;
    }
    const response = await updateYearPlanning(type, values);
    if (!response) {
      return;
    }
    this.setYearPlanning(type, response);
    showNotificationModal({
      title: "Изменения сохранены",
      type: "success",
    });
  };

  approveYearPlanningConfirm = async (
    type: keyof typeof YearPlanningTypes,
    values: IYearPlanningArticle[],
    messages?: ReactNode[]
  ) => {
    const planningType = type === "revenue" ? "доходов" : "расходов";

    showConfirmModal({
      title: `Утвердить планирование ${planningType}?`,
      confirmButtonLabel: "Утвердить",
      messages,
      onConfirm: () => this.fetchApproveYearPlanning(type, values),
      minWidth: "450px",
      maxWidth: "450px",
    });
  };

  private fetchApproveYearPlanning = async (
    type: keyof typeof YearPlanningTypes,
    values: IYearPlanningArticle[]
  ) => {
    if (!this.selectedYear) {
      showNotificationModal({ title: "Выберите год", type: "failure" });
      return;
    }
    const update = await updateYearPlanning(type, values);
    if (!update) {
      return;
    }
    const response = await approveYearPlanning(type, dayjs(this.selectedYear).format("YYYY"));
    if (!response) {
      return;
    }
    this.setYearPlanning(type, response);
    this.setPeriodStatus(type, YearPlanningPeriodStatus.approved);
    showNotificationModal({
      title: "Планирование утверждено",
      type: "success",
    });
  };
}
