import { Segmented } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo } from "react";
import { MonthSelectorSeparated } from "widgets/month-selector/separated";
import { IncomePlanningYearTabs, YearPlanningTypes } from "./types";
import cls from "./styles.module.scss";
import { InfoMessage } from "widgets/info-message";
import { useStore } from "store";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

interface IncomePlanningYearHeaderProps {
  onSave: () => void;
  onApprove: () => void;
}

const IncomePlanningYearHeaderComponent: FC<IncomePlanningYearHeaderProps> = ({
  onSave,
  onApprove,
}) => {
  const {
    RootStore: {
      YearPlanningStore: {
        selectedYear,
        setSelectedYear,
        setYearPlanningExpenses,
        setYearPlanningRevenue,
        activeTab,
        setActiveTab,
        setYearPlanning,
        currentPeriodStatus,
      },
    },
  } = useStore();

  const { getValues } = useFormContext();

  const currentButtonLabel = useMemo(() => {
    if (YearPlanningTypes[activeTab] === "expenses") return "расходы";
    if (YearPlanningTypes[activeTab] === "revenue") return "выручку";
    return "";
  }, [activeTab]);

  const onSegmentChange = useCallback(
    (val: string) => {
      setYearPlanning(val === "Выручка" ? "expenses" : "revenue", getValues().articles);
      setActiveTab((IncomePlanningYearTabs as any)[val]);
    },
    [setActiveTab, setYearPlanning]
  );

  const onMonthSelect = useCallback(
    (date: Date) => {
      setYearPlanningRevenue([]);
      setYearPlanningExpenses([]);
      setSelectedYear(date);
    },
    [setSelectedYear, setYearPlanningExpenses, setYearPlanningRevenue]
  );

  const infoIsShow = useMemo(
    () =>
      dayjs(selectedYear).isSame(dayjs(), "year") || dayjs(selectedYear).isAfter(dayjs(), "year"),
    [selectedYear]
  );

  return (
    <div className={cls["header"]}>
      <div className={cls["title-container"]}>
        <h2>Планирование на год</h2>
        <MonthSelectorSeparated
          selectedDate={selectedYear}
          setSelectedDate={onMonthSelect}
          withMonth={false}
          minDate={new Date(2025, 0, 1)}
        />
        <Segmented
          className={cls["tabs"]}
          value={IncomePlanningYearTabs[activeTab]}
          options={["Выручка", "Расходы"]}
          onChange={onSegmentChange}
        />
      </div>
      <div className={cls["control-container"]}>
        <div>
          {infoIsShow && (
            <InfoMessage
              width="500px"
              height="fit-content"
              messages={[
                <p>{`Внеси информацию по планам на следующий год и согласуй планы с финпартнером до 15 декабря ${
                  selectedYear.getFullYear() - 1
                } года`}</p>,
                <p>План отразится в отчете PnL после утверждения</p>,
              ]}
            />
          )}
        </div>
        <div className={cls["buttons-container"]}>
          {!["approved", null].includes(currentPeriodStatus) && (
            <>
              <button className={cls["button-secondary"]} onClick={onApprove}>
                Утвердить {currentButtonLabel}
              </button>
              <button className={cls["button-primary"]} onClick={onSave}>
                Сохранить {currentButtonLabel}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const IncomePlanningYearHeader = observer(IncomePlanningYearHeaderComponent);
