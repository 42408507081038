import styles from './styles.module.scss';
import {useEffect, useMemo, useState} from "react";
import {
    getMonthCheckAwards,
    getMonthCheckDocuments,
    getMonthCheckLoans,
    getMonthCheckMoneyTransactions, getMonthClose,
    getMonthLast
} from "../../api/monthEnd";
import {Button} from "../../ui-new/button";
import {dayjs} from "utils/plugins/dayjs"
import {CalendarIcon} from "./icons/Calendar";
import {List} from "./components/list";
import {Link} from "react-router-dom";
import {CheckInfoModal} from "./components/check-info-modal";
import {showAlertPopup} from "../../ui/alert";
import {ShowAlertPopupType} from "../../utils/const/shared";

const defaultItemsState = [
    { title: 'Проверь, есть ли незакрытые Реализации с другими спотами', errorMessage: <span>Есть незакрытые <Link to="/main/loan">Реализации</Link></span>, value: 'loan', isLoading: false, success: false, message: '' },
    { title: 'Проверь, выполнено ли начисление зарплаты за отчетный период', errorMessage: <span>У тебя есть неутвержденные <Link to="/main/fund" className={styles.link}>вознаграждения</Link></span>, value: 'award', isLoading: false, success: false, message: '' },
    { title: 'Убедись, что все необходимые документы утверждены, а неактуальные помечены на удаление', errorMessage: <span>Перейди в <Link to="/main/registry" className={styles.link}>Реестр документов</Link> и утверди или пометь на удаление неутвержденные документы</span>, value: 'document', isLoading: false, success: false, message: '' },
    { title: <span>Убедись, что у тебя не осталось просроченных <Link to="/main/loans/contragents" className={styles.link}>задолженностей</Link></span> },
    { title: <span>Проверь, что вся необходимая информация <Link to="/main/statements" className={styles.link}>о движении денежных средств</Link> отражена в виде банковских выписок и кассовых ордеров. Сверься с состоянием кассы и расчетного счета и подтверди корректность остатков</span>, value: 'accepts' },
    { title: <span>Проверь отчет <Link to='/main/stock/report' className={styles.link}>остатки на складе</Link> и в случае расхождений внеси необходимые исправления - добавь накладные и авансовые отчеты, устрани недостатки инвентаризации. Если все проверки выполнены и отрицательных остатков нет - жми кнопку</span>, value: 'month-close', isLoading: false, success: false },
]

export const MonthEnd = () => {

    const [items, setItems] = useState([...defaultItemsState]);
    const [isFirstRequest, setFirstRequest] = useState(false);
    const [currentMonthName, setCurrentMonthName] = useState('');
    const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(false);
    const [isMonthEndLoading, setMonthEndLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [isCheckInfoModal, setCheckInfoModal] = useState(false);
    const [isMonthAccepted, setMonthAccepted] = useState(false);
    const [isMonthClosed, setMonthClosed] = useState(false);
    const [monthLastError, setMonthLastError] = useState('');
    const [mainError, setMainError] = useState('');
    const [isMonthClosedLoading, setMonthClosedLoading] = useState(false);

    const currentMonthLabel = useMemo(() => {
        const nextMonth = dayjs(new Date(currentDate)).add(1, 'month').toDate();
        const longMonthName = new Date(nextMonth).toLocaleDateString('ru', { month: 'long', year: 'numeric', day: 'numeric' });
        return isUpdateButtonDisabled ? `Будет доступно ${longMonthName}` : `Сейчас мы закрываем ${currentMonthName}`
    }, [currentMonthName, isUpdateButtonDisabled, currentDate]);

    const isSubmitDisabled = useMemo(() => {
        const neccesseryItems = items.filter(item => item.value === 'loan' || item.value === 'document' || item.value === 'award');
        const someUnsuccess = neccesseryItems.some((item) => !item.success)
        return someUnsuccess
    }, [items, isMonthAccepted])

    const setItemsLoading = (value: boolean) => {
        setItems((prev: any) => {
            return prev.map((item: any) => ({
                ...item,
                isLoading: value,
            }));
        });
    }

    const openModal = () => {
        setCheckInfoModal(true)
    }

    const closeModal = () => {
        setCheckInfoModal(false)
    }

    const onModalSave = () => {
        setMonthAccepted(true);
        setCheckInfoModal(false);
    }

    const initMonthEnd = async () => {
        setMonthEndLoading(true);

        try {
            const response = await getMonthLast();
            const { month, available_approve_day_start, available_approve_day_end, message, status } = response.data;
            const { success } = response;

            const currentDate = dayjs().add(1, 'month').date();
            setCurrentMonthName(dayjs(month).format("MMMM"));
            setCurrentDate(month);

            if (status === 'error') {
                return setMainError('Закрытие месяца выполнилось с ошибками. Обратись в поддержку!');
            }

            if (!success) {
                setUpdateButtonDisabled(true);
                return setMonthLastError(message);
            }

            if (status === 'in-approved') {
                return setUpdateButtonDisabled(true);
            }

            setUpdateButtonDisabled(!(currentDate >= available_approve_day_start && currentDate < available_approve_day_end));
        } catch (e) {
        } finally {
            setMonthEndLoading(false);
        }
    }

    const applyItemMethods = async () => {
        setItemsLoading(true);
        setFirstRequest(true);

        const [checkedLoans, checkedAwards, checkedDocuments] = await Promise.all([
            getMonthCheckLoans(currentDate),
            getMonthCheckAwards(currentDate),
            getMonthCheckDocuments(currentDate),
        ]);

        setItems((prev) => {
            const temp = [...prev];

            temp[0].success = checkedLoans.success;
            temp[0].message = checkedLoans.message;
            temp[1].success = checkedAwards.success;
            temp[1].message = checkedAwards.message;
            temp[2].success = checkedDocuments.success;
            temp[2].message = checkedDocuments.message;

            return temp;
        });

        setItemsLoading(false);
    }

    const onMonthClose = async () => {
        setMonthClosedLoading(true);

        try {
            const response = await getMonthClose(currentDate);

            if (response.success) {
                setMonthClosed(true);
                showAlertPopup('Идет закрытие месяца...', 'Обнови страницу через несколько минут', ShowAlertPopupType.SUCCESS)
            }

            setFirstRequest(false);
            await initMonthEnd();
            setMonthAccepted(false);
            setItems([...defaultItemsState]);
        } catch (e) {
            // @ts-ignore
            const message = e.response.data.message;
            setItems((prev) => {
                const temp = [...prev];

                temp[5].success = false;
                temp[5].message = message;

                return temp;
            });
        } finally {
            setMonthClosedLoading(false);
        }
    }

    useEffect(() => {
        initMonthEnd();
    }, []);

    return <div className={styles.pageWrapper}>
        <header className={styles.pageHeader}>
            <h1 className={styles.pageHeaderText}>
                Закрытие месяца
            </h1>

            <div className={styles.headerActions}>
                <span className={styles.subtitle}>
                    Выполни проверки, внеси недостающую информацию в систему, исправь ошибки учета и <span className={styles.filledText}>закрой отчетный период до 5 числа.</span>
                    Чтобы закрыть последний отчетный период - все предыдущие периоды должны быть закрыты
                </span>

                <div className={styles.closePeriodWrapper}>
                    <div className={styles.closePeriodContainer}>
                        {isMonthEndLoading && <span className={styles.currentMonthLabel}>Загрузка даты...</span>}
                        {!isMonthEndLoading &&
                            <div className={styles.currentMonth}>
                                <span className={styles.currentMonthLabel}>{currentMonthLabel}</span>

                                <div className={styles.monthWrapper}>
                                    <CalendarIcon className={styles.icon}/>
                                    <span>{currentMonthName}</span>
                                </div>
                            </div>
                        }

                        <Button onClick={applyItemMethods} disabled={isUpdateButtonDisabled} isLoading={isMonthEndLoading}>
                            Закрыть период
                        </Button>
                    </div>

                    {monthLastError && <div className={styles.errorMessage}>
                        {monthLastError}
                    </div>}
                </div>
            </div>

            {mainError && <div className={styles.errorText}>
                {mainError}
            </div>}
        </header>

        <div className={styles.listContainer}>
            <List
                items={items}
                openModal={openModal}
                isMonthAccepted={isMonthAccepted}
                isSubmitDisabled={isSubmitDisabled}
                onMonthClose={onMonthClose}
                isFirstRequest={isFirstRequest}
                isMonthCloseLoading={isMonthClosedLoading}
            />
        </div>

        <CheckInfoModal
            isOpen={isCheckInfoModal}
            onClose={closeModal}
            onSave={onModalSave}
            date={currentDate}
        />
    </div>
}