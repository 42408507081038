import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { InfoMessage } from "widgets/info-message";

const TableInfoMessageComponent = () => {
  const {
    RootStore: {
      SalaryFundStore: { infoMessage },
    },
  } = useStore();

  if (!infoMessage) return <div style={{ height: "54px" }}></div>;

  return (
    <div style={{ justifySelf: "end" }}>
      <InfoMessage infoMessage={infoMessage} />
    </div>
  );
};

export const TableInfoMessage = observer(TableInfoMessageComponent);
