import styles from './styles.module.scss';
import {Modal} from "ui-new/modal";
import {ModalCard} from "ui-new/modal-card/modalCard";
import {Button} from "ui-new/button";
import {useEffect, useState} from "react";
import {getMonthCheckMoneyTransactions} from "api/monthEnd";
import {Loader} from "ui-new/loader";

export interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    minWidth?: string;
    maxWidth?: string;
    date: string;
}

export const CheckInfoModal = ({ isOpen, onClose, onSave, date }: Props) => {

    const [isInitLoading, setInitLoading] = useState(false);
    const [modalData, setModalData] = useState<any>(null);

    const initModal = async () => {
        setInitLoading(true);

        try {
            const response = await getMonthCheckMoneyTransactions(date);
            setModalData({ cash: response.data.cash, nonCash: response.data.non_cash });
        } catch {}
        finally {
            setInitLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) initModal();
    }, [isOpen]);

    return <Modal isOpen={isOpen} onClose={onClose} className={styles.modalContainer}>
        <ModalCard onClose={onClose} className={styles.modalCard}>
            {isInitLoading && <div className={styles.modalLoader}>
                <Loader/>
            </div>}

            {!isInitLoading && <div className={styles.modalWrapper}>
                <div className={styles.modalTitleContainer}>
                    <h3 className={styles.modalTitleContainerText}>
                        Ты уверен, что состояние
                        кассы и расчетного счета
                        верно?
                    </h3>
                </div>

                <div className={styles.modalDescriptionContainer}>
                <span className={styles.modalDescriptionContainerText}>
                    В кассе: {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(modalData?.cash)}
                </span>
                    <span className={styles.modalDescriptionContainerText}>
                    На расчетном счету: {new Intl.NumberFormat('ru-RU', {
                        style: 'currency',
                        currency: 'RUB'
                    }).format(modalData?.nonCash)}
                </span>
                </div>

                <div className={styles.modalButtons}>
                    <Button onClick={onClose} theme='outlined'>Отменить</Button>
                    <Button onClick={onSave}>Да, я все проверил</Button>
                </div>
            </div>}
        </ModalCard>
    </Modal>
}