import styles from "./styles.module.scss"
import { ListItem } from "../list-item";

export interface Props {
    items: any[];
    openModal: () => void;
    isMonthAccepted: boolean;
    isSubmitDisabled: boolean;
    onMonthClose: () => Promise<void>;
    isFirstRequest: boolean;
    isMonthCloseLoading: boolean;
}

export function List ({ items, openModal, isMonthAccepted, isSubmitDisabled, onMonthClose, isFirstRequest, isMonthCloseLoading }: Props) {

    return (
        <ul className={styles.list}>
            {
                items.map((item, index) => {
                    return <ListItem
                        openModal={openModal}
                        item={item}
                        index={index}
                        key={item.title}
                        isMonthAccepted={isMonthAccepted}
                        isSubmitDisabled={isSubmitDisabled}
                        onMonthClose={onMonthClose}
                        isFirstRequest={isFirstRequest}
                        isMonthCloseLoading={isMonthCloseLoading}
                    />
                })
            }
        </ul>
    );
}