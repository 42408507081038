import { FC, ReactNode } from "react";
import cls from "./styles.module.scss";
import { WarningIcon } from "ui-new/icons/warning-icon";

interface InfoMessageProps {
  infoMessage?: string;
  messages?: ReactNode[];
  width?: string;
  height?: string;
}

export const InfoMessage: FC<InfoMessageProps> = ({ infoMessage, width, height, messages }) => {
  return (
    <div className={cls["info-message"]} style={{ width, height }}>
      <WarningIcon />
      {infoMessage && infoMessage.length > 0 && <p title={infoMessage}>{infoMessage}</p>}
      {messages && messages?.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "100%" }}>
          {messages}
        </div>
      )}
    </div>
  );
};
