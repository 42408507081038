import { ISalary } from "pages/money-transactions/money-transaction-edit/types";

export interface IMoneyTransaction {
  id: number;
  guid_1c: string | null;
  status_id: IMoneyTransactionStatus["id"] | null;
  status_title: IMoneyTransactionStatus["title"] | null;
  type_id: IMoneyTransactionType["id"] | null;
  type_title: IMoneyTransactionType["title"] | null;
  pay_type_id: IMoneyTransactionPayType["id"] | null;
  pay_type_title: IMoneyTransactionPayType["title"] | null;
  operation_type_id: IMoneyTransactionOperationType["id"] | null;
  operation_type_title: IMoneyTransactionOperationType["title"] | null;
  cash_flow_article_id: IMoneyTransactionCashFlowArticle["id"] | null;
  cash_flow_article_title: IMoneyTransactionCashFlowArticle["title"] | null;
  number: string;
  //YYYY-MM-DD
  date: string | null;
  date_delivery: string | null;
  amount: number | null;
  user_id: number | null;
  user_title: string | null;
  spot_id: number | null;
  spot_title: string | null;
  spot_bank_account_id: number | null;
  spot_bank_account_number: string | null;
  contragent_id: number | null;
  contragent_type: string | null;
  contragent_title: string | null;
  contragent_inn: number | null;
  deal_id: number | null;
  deal_title: string | null;
  description: string | null;
  commission: number | null;

  //2024-10-22T15:10:56.000000Z
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  tax_rate: number | null;
  items: ISalary[] | null;
}

export interface IMoneyTransactionOperationType {
  id: number;
  title: string;
  pay_type_id: IMoneyTransactionPayType["id"];
  pay_type_title: IMoneyTransactionPayType["title"];
  pay_type_code: IMoneyTransactionPayType["code"];
  code: null;
  money_transaction_type_id: IMoneyTransactionType["id"];
  money_transaction_type_title: IMoneyTransactionType["title"];
  money_transaction_type_code: IMoneyTransactionType["code"];
  fields: IMoneyTransactionFieldsByOperation;
}

export interface IMoneyTransactionCashFlowArticle {
  id: number;
  //"2024-10-22T00:55:27.000000Z"
  created_at: string;
  updated_at: string;
  guid_1c: string | null;
  title: string;
  code: string;
  parent_id: number | null;
  is_group: boolean;
  deleted_at: string | null;
}

export interface IMoneyTransactionStatus {
  id: number;
  title: string;
}

export interface IMoneyTransactionType {
  id: number;

  //"2024-10-21T23:19:28.000000Z"
  created_at: string;
  updated_at: string | null;
  title: string;

  //CASH, NON_CASH
  code: string;
  deleted_at: null | string;
}

export interface IMoneyTransactionPayType {
  id: number;

  //"2024-10-21T23:19:28.000000Z"
  created_at: string;
  updated_at: string | null;
  title: string;

  //OUT, IN
  code: string;
  deleted_at: null | string;
}

export interface IMoneyTransactionPnLArticle {
  id: number;
  //"2024-10-31T09:49:30.000000Z"
  created_at: string;
  updated_at: string;
  guid_1c: string | null;
  title: string;
  code: string;
  parent_id: number | null;
  is_group: number;
  deleted_at: string | null;
}

export interface IMoneyTransactionCommitment {
  id: number;
  //"2024-10-31T09:49:30.000000Z"
  created_at: string;
  updated_at: string;
  guid_1c: string | null;
  title: string;
  deleted_at: string | null;
}

export interface IMoneyTransactionTax {
  id: number;
  //"2024-10-31T09:49:30.000000Z"
  created_at: string;
  updated_at: string;
  guid_1c: string | null;
  title: string;
  code: string;
  deleted_at: string | null;
}

export enum MoneyTransactionDynamicFormFields {
  CashFlowArticleId = "cash_flow_article_id",
  PnLArticleId = "pnl_article_id",
  ContragentId = "contragent_id",
  Commission = "commission",
  TaxRate = "tax_rate",
  TaxAmount = "tax_amount",
  TaxTypeId = "tax_type_id",
  TypeOfCommitmentId = "type_of_commitment_id",
  Period = "period",
  UsersTable = "users_table",
  ContragentType = "contragent_type",
}

export enum MoneyTransactionFormFields {
  CashFlowArticleId = "cash_flow_article_id",
  PnLArticleId = "pnl_article_id",
  ContragentId = "contragent_id",
  Commission = "commission",
  TaxRate = "tax_rate",
  TaxAmount = "tax_amount",
  TaxTypeId = "tax_type_id",
  TypeOfCommitmentId = "type_of_commitment_id",
  Period = "period",
  UserTable = "users_table",
  ContragentType = "contragent_type",
}

export interface IField {
  show: boolean;
  disabled: boolean;
  default?: any;
}

type IMoneyTransactionFieldsInOperation = {
  [key in MoneyTransactionDynamicFormFields]: IField;
};

export type IMoneyTransactionFieldsByOperation = Partial<IMoneyTransactionFieldsInOperation>;
