import {api} from "./api";

export const BASE_API_URL_V3 = `${process.env.REACT_APP_API_BASE}/api/v3/`;
export const BASE_API_CONFIG = { baseURL: BASE_API_URL_V3 };

export async function getMonthLast(): Promise<any | null> {
    try {
        const response = await api.get('/month/getLast', BASE_API_CONFIG);
        return {
            data: response.data.data,
            success: response.data.success,
        };
    } catch {
        return null;
    }
}

export async function getMonthCheckLoans(date: string) {
    const query = new URLSearchParams();
    query.append("date", date);

    try {
        const response = await api.get('/month/check/loans', {
            params: query,
            ...BASE_API_CONFIG,
        });
        return response.data;
    } catch {
        return null;
    }
}

export async function getMonthCheckAwards(date: string) {
    const query = new URLSearchParams();
    query.append("date", date);

    try {
        const response = await api.get('/month/check/awards', {
            params: query,
            ...BASE_API_CONFIG
        });
        return response.data;
    } catch {
        return null;
    }
}

export async function getMonthCheckDocuments(date: string) {
    const query = new URLSearchParams();
    query.append("date", date);

    try {
        const response = await api.get('/month/check/documents', {
            params: query,
            ...BASE_API_CONFIG
        });
        return response.data;
    } catch {
        return null;
    }
}

export async function getMonthCheckMoneyTransactions(date: string) {
    const query = new URLSearchParams();
    query.append("date", date);

    try {
        const response = await api.get('/month/check/moneyTransactions', {
            params: query,
            ...BASE_API_CONFIG
        });
        return response.data;
    } catch {
        return null;
    }
}

export async function getMonthClose(date: string) {
    const query = new URLSearchParams();
    query.append("date", date);

    const response = await api.get('/month/close', {
        params: query,
        ...BASE_API_CONFIG
    });

    return response.data;
}
