import {
  IYearPlanningArticle,
  YearPlanningPeriodStatus,
  YearPlanningTypes,
} from "pages/year-planning/types";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";

export const getYearPlanning = async (type: keyof typeof YearPlanningTypes, year: string) => {
  try {
    const response = await api.get<
      IBaseResponse<IYearPlanningArticle[]> & { period_status: YearPlanningPeriodStatus }
    >(`yearly_planning/${type}/get`, { params: { year } });
    return { data: response.data.data, period_status: response.data.period_status };
  } catch (error) {
    return onError(error);
  }
};

export const updateYearPlanning = async (
  type: keyof typeof YearPlanningTypes,
  yearData: IYearPlanningArticle[]
) => {
  try {
    const response = await api.post<IBaseResponse<IYearPlanningArticle[]>>(
      `yearly_planning/${type}/update`,
      yearData
    );
    if (!response.data.success)
      throw new Error((response.data as any).message ?? "Что-то пошло не так");
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
};

export const approveYearPlanning = async (type: keyof typeof YearPlanningTypes, year: string) => {
  try {
    const response = await api.post<IBaseResponse<IYearPlanningArticle[]>>(
      `yearly_planning/${type}/approve`,
      { year }
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
};
