import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "./table-styles.module.scss";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import { Row } from "./row";
import { IYearPlanningArticle } from "../types";
import { useFieldArray, useFormContext } from "react-hook-form";
import { TableHeader } from "./table-header";

dayjs.locale(ru);

const MONTHS_IN_YEAR = 12;

interface TableProps {}

const arrFieldName = "articles";

const TableComponent: FC<TableProps> = () => {
  const { control } = useFormContext<{ articles: IYearPlanningArticle[] }>();

  const { fields } = useFieldArray({ control, name: arrFieldName, keyName: "_id" });

  const articlesData = useMemo(
    () =>
      fields.map((article, i) => (
        <Row parentName={arrFieldName} key={article._id} index={i} article={article} />
      )),
    [fields]
  );

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={268} />
        <ColGroup />
        <col width={120} />
      </colgroup>
      <TableHeader />
      <tbody className={cls["tbody"]}>{articlesData}</tbody>
    </table>
  );
};

const ColGroup = () => (
  <>
    {Array.from({ length: MONTHS_IN_YEAR }).map((_, i) => (
      <col width={120} key={i} />
    ))}
  </>
);

export const Table = observer(TableComponent);
