import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { BigSkeleton, Button, EButtonVariant, EInputStyleVariant, EInputVariant, Input } from "ui";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { modalContent } from "widgets/popup-modal/modalContent";
import { PopupModal } from "widgets";
import { useStore } from "store";
import { toJS } from "mobx";
import { AddButonIcon, BackArrowIcon, DeletedIcon } from "../icons";
import { DayPickerNew } from "../../../widgets/datepickers/day-picker-new";
import TimeField from "react-simple-timefield";
import { EFontVariant } from "../../../assets/styles/enums";
import { showAlertPopup } from "ui/alert";
import { ManualInputTable } from "./components/ManualInputTable/ManualInputTable";
import {
  getAccountsOfManualOperations,
  getViewOfManualOperations,
  postCreateManualOperation,
} from "../../../api/operationInput";
import type { Account } from "../types";
import type { TransactionRequest } from "./components/ManualInputTableRow/ManualInputTableRow";
import { transactionsRequestBody } from "../const";
import type { ExistingTransactions } from "../types";
import type { Nullable } from "../../../assets/types";
import { ShowAlertPopupType } from "../../../utils/const/shared";

export interface Transaction {
  index: number;
  debet: string;
  debet_count: number;
  credit: string;
  credit_count: number;
  sum: number;
}

export interface ManualOperationAccount {
  code: string;
  guid: string;
  work_name: string;
  label: string;
  value: string;
}

interface TransactionRequestBody {
  amount: number;
  date: string;
  transactions: TransactionRequest[];
}

export const OperationInputEditPage = observer(() => {
  const {
    RootStore: {
      OperationInputEditStore: {
        fetchViewOfManualOperations,
        fetchSubcontoTypesOfManualOperations,
        pickedDate,
        setPickedDate,
        manualOperation,
        setManualOperation,
        fetchDeleteManualOperation,
        subcontoTypes,
        fetchAccountsManualOperations,
        fetchCreateManualOperation,
        isNewOperation,
        setIsNewOperation,
        isLoading,
        setInitDate,
      },
      setModalIsActive,
    },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.hash.slice(1);

  const addButtonBottomRef = useRef(null);
  const [isBottomAddButton, setBottomAddButton] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [accounts, setAccounts] = useState<ManualOperationAccount[]>([]);
  const [isAccountsLoading, setAccountsLoading] = useState(false);
  const [transactions, setTransaction] = useState<Transaction[]>([]);
  const [mainRequestBody, setMainRequestBody] = useState<TransactionRequestBody>({
    ...transactionsRequestBody,
  });
  const [modalType, setModalType] = useState<"save" | "quit" | null>(null);
  const [existingTransactions, setExistingTransactions] =
    useState<Nullable<ExistingTransactions>>(null);

  const fetchAccounts = async () => {
    setAccountsLoading(true);

    try {
      const response = await getAccountsOfManualOperations();

      const accounts = response.data.map((val: Account) => ({
        code: val.code,
        label: `${val.code} ${val.work_name}`,
        work_name: val.work_name,
        guid: val.guid,
        value: val.guid,
      }));

      setAccounts(accounts);
    } catch {
      showAlertPopup(
        "Возникла ошибка",
        "Не удалось получить данные с сервера",
        ShowAlertPopupType.ERROR
      );
    } finally {
      setAccountsLoading(false);
    }
  };

  const onTransactionRequestHandler = (transactions: TransactionRequest[]) => {
    setMainRequestBody(prevState => ({ ...prevState, transactions }));
  };

  const onSaveClick = async () => {
    setButtonDisabled(true);
    console.log("отображаем manualOperation");
    console.log(manualOperation);

    const date = toJS(manualOperation)?.date;
    const amount = toJS(manualOperation)?.amount;
    const comment = toJS(manualOperation)?.comment;

    const mappedMainRequestBody = {
      ...mainRequestBody,
      transactions: mainRequestBody.transactions.map(item => ({
        ...item,
        amount: parseFloat(item.amount),
      })),
    };

    console.log("отображаем mainRequestBody");
    console.log(mainRequestBody);

    const response = await postCreateManualOperation({
      ...mappedMainRequestBody,
      date,
      amount,
      comment,
    });

    if (!response) {
      setButtonDisabled(false);
      return;
    }

    showAlertPopup("Сохранено!", "Данные успешно сохранены!", ShowAlertPopupType.SUCCESS);
    navigate("/main/input");

    setButtonDisabled(false);
  };

  const onAddButtonClick = () => {
    const newTransaction: Transaction = {
      index: transactions.length + 1,
      debet: "",
      credit: "",
      credit_count: 0,
      debet_count: 0,
      sum: 0,
    };
    setTransaction(prevState => [...prevState, newTransaction]);
    if (!manualOperation) return;
    setManualOperation({
      ...toJS(manualOperation),
      transactions: [
        ...toJS(manualOperation).transactions,
        {
          row_number: toJS(manualOperation).transactions.length + 1,
          debit_subconto: [],
          credit_account: null,
          amount: 0,
          credit_subconto: [],
          debit_account: null,
        },
      ],
    });
  };

  const getExistingTransactions = async (id: number) => {
    const { data, success } = await getViewOfManualOperations(id);

    if (!success) {
      showAlertPopup(
        "Ошибка!",
        "Не удалось получить данные, попробуйте позже",
        ShowAlertPopupType.ERROR
      );
      return;
    }

    setExistingTransactions(data);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setBottomAddButton(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    if (addButtonBottomRef.current) {
      observer.observe(addButtonBottomRef.current);
    }

    return () => {
      if (addButtonBottomRef.current) {
        observer.unobserve(addButtonBottomRef.current);
      }
    };
  }, [transactions]);

  useEffect(() => {
    setIsNewOperation(location.hash === "#new");
  }, [location]);

  useEffect(() => {
    setIsNewOperation(location.hash === "#new");
    if (location.hash === "#new") {
      setManualOperation({
        date: `${new Date().toLocaleDateString("se-Se")}T${
          new Date().toTimeString().split(" ")[0]
        }`,
        amount: 0,
        transactions: [],
      });
    } else {
      setManualOperation(null);
      fetchViewOfManualOperations(+id);
      getExistingTransactions(+id);
    }

    fetchAccountsManualOperations();
    fetchSubcontoTypesOfManualOperations();
    fetchAccounts();

    return () => setInitDate(new Date());
  }, []);

  //Заглушил кнопку пометить на удаление, пока со стороны 1С этот функционал не работает. 292 строка

  return (
    <>
      <div className="statementedit__header">
        <div className="breadcrumbs">
          <p className="grey" onClick={() => navigate("/main/input/")}>
            Ручной ввод операции
          </p>
          /<p className="black">{isNewOperation ? "Новая операция" : "Просмотр операции"}</p>
        </div>
        <div className="title">
          <div className="text">
            <button
              className="backlink"
              onClick={() => {
                navigate("/main/input");
              }}
            >
              <BackArrowIcon />
            </button>
            Ручной ввод
          </div>
          {isNewOperation ? (
            <div className="buttons">
              <Button
                variant={EButtonVariant.fillButton}
                text={"Сохранить"}
                maxWidth={"99px"}
                disabled={isButtonDisabled}
                onClick={() => onSaveClick()}
              />
            </div>
          ) : (
            <div className="buttons">
              {toJS(manualOperation)?.status === 2 ? (
                <div className={"deleted"}>
                  <DeletedIcon />
                  <p>Помечено на удаление</p>
                </div>
              ) : (
                false && (
                  <Button
                    variant={EButtonVariant.fillButton}
                    text={"Пометить на удаление"}
                    maxWidth={"183px"}
                    onClick={() => {
                      fetchDeleteManualOperation(+id);
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div style={{ padding: "0 20px" }}>
          <BigSkeleton />
        </div>
      ) : (
        <div className="operation-edit">
          <div className="operation-edit__inputs">
            <div className="operation-edit__inputs-row">
              {toJS(manualOperation) && manualOperation ? (
                <>
                  <div className="operation-edit__inputs-row__element">
                    <span>Дата</span>
                    <DayPickerNew
                      date={pickedDate}
                      dateSetter={setPickedDate}
                      disabled={!isNewOperation}
                    />
                  </div>
                  <div className="operation-edit__inputs-row__element">
                    <span>Время</span>

                    <TimeField
                      value={toJS(manualOperation)?.date?.split("T")[1]}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, value: any) => {
                        setManualOperation({
                          ...toJS(manualOperation),
                          date: `${toJS(manualOperation).date.split("T")[0]}T${value}:00`,
                        });
                      }}
                      input={
                        <Input
                          type={EInputVariant.text}
                          variant={EInputStyleVariant.basicInput}
                          font={EFontVariant.body1Regular}
                          height={"44px"}
                          borderRadius={"16px"}
                          name="fact_st"
                          disabled={!isNewOperation}
                        />
                      }
                    />
                  </div>
                  <div className="operation-edit__inputs-row__element">
                    <span>Сумма, ₽</span>
                    <Input
                      type={EInputVariant.text}
                      variant={EInputStyleVariant.basicInput}
                      height={"44px"}
                      borderRadius={"16px"}
                      placeholder={"Общая сумма операции"}
                      value={
                        toJS(manualOperation)?.amount?.toString() ||
                        toJS(manualOperation)?.document_amount?.toString() ||
                        ""
                      }
                      disabled={!isNewOperation}
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        const { value } = event.target as HTMLInputElement;

                        if (value === "" || /^\d+$/.test(value)) {
                          setManualOperation({
                            ...toJS(manualOperation),
                            amount: +value,
                          });
                        }
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="operation-edit__inputs-row">
              <div className="operation-edit__inputs-row__element">
                <span>Комментарий</span>
                <span className="max">
                  {manualOperation && toJS(manualOperation)?.comment
                    ? `${255 - toJS(manualOperation)?.comment?.length}`
                    : ""}
                </span>
                <Input
                  type={EInputVariant.text}
                  variant={EInputStyleVariant.basicInput}
                  height={"44px"}
                  borderRadius={"16px"}
                  placeholder={"Добавьте описание"}
                  value={toJS(manualOperation)?.comment ? toJS(manualOperation)?.comment : ""}
                  onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    const { value } = event.target as HTMLInputElement;
                    setManualOperation({
                      ...toJS(manualOperation),
                      comment: value,
                    });
                  }}
                  maxLength={255}
                  disabled={!isNewOperation}
                />
              </div>
              {isNewOperation &&
                manualOperation &&
                toJS(manualOperation)?.transactions?.length < 200 && (
                  <div
                    ref={addButtonBottomRef}
                    className="operation-edit__inputs-row__element button"
                  >
                    <button onClick={() => onAddButtonClick()}>
                      <AddButonIcon />
                      Добавить
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="table-container">
            {
              <ManualInputTable
                transactions={transactions}
                existingTransactions={existingTransactions}
                accounts={accounts}
                accountsLoading={isAccountsLoading}
                transactionsBodyUpdate={onTransactionRequestHandler}
                isNew={location.hash === "#new"}
              />
            }
          </div>
          {!!transactions.length && isBottomAddButton && (
            <div className="add-button-container">
              {isNewOperation &&
                manualOperation &&
                toJS(manualOperation)?.transactions?.length < 200 && (
                  <div className="operation-edit__inputs-row__element button">
                    <button onClick={() => onAddButtonClick()}>
                      <AddButonIcon />
                      Добавить
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
});
