export enum IncomePlanningYearTabs {
  "Расходы" = 0,
  "Выручка",
}

export enum YearPlanningTypes {
  "expenses" = 0,
  "revenue",
}

export interface IYearPlanningArticle {
  id: number;
  type: YearPlanningArticleTypes;
  is_money: boolean;
  title: string;
  parent_id: number | null;
  percent: number;
  is_editable: boolean;
  items: IYearPlanningArticle[];
  results: any[];

  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  july: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  total: number;
}

export enum YearPlanningPeriodStatus {
  not_planned = "not_planned",
  approved = "approved",
}

export enum YearPlanningArticleTypes {
  number = "number",
  percent = "percent",
  info_title = "info_title",
  percent_in_field = "percent_in_field",
}

export enum YearPlanningArticleTypesSymbol {
  number = "₽",
  percent = "%",
}
