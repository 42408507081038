import { FC } from "react";

interface IconProps {
  className?: string;
}

export const ArrowDownIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 5L11 1"
        stroke="#9747FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const MinusIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="2"
      viewBox="0 0 15 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1H9.5H14"
        stroke="#9747FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
