import React, {useCallback, useEffect, useMemo} from "react";
import {useStore} from "../../../store";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import HeaderPendingStatusButtons from "./headerPendingStatusButtons";
import {observer} from "mobx-react-lite";
import {BackButton, DeletedIcon} from "../icons";
import {toJS} from 'mobx';
import {fetchDistributeDocument} from "../service";
import type {DistributionDocumentType} from "../types";
import {Button, EButtonVariant} from "../../../ui";
import {EFontVariant} from "../../../assets/styles/enums";
import {fetchUpdateAdvanceReport} from "../../../api/registryDocument";

interface DocumentHeaderProps {
    navigate: ReturnType<typeof useNavigate>;
    location: ReturnType<typeof useLocation>;
    deleteModalIsActive: boolean;
    setDeleteModalIsActive: (arg0: boolean) => void;
    type: string;
}

const DocumentHeader = observer(
    ({
         navigate,
         location,
         deleteModalIsActive,
         setDeleteModalIsActive,
         type,
     }: DocumentHeaderProps) => {
        const {RootStore} = useStore();
        const {
            RegistryDocumentStore: {advanceReport, draftComment, documentData, fetchReport},
        } = RootStore;

        const [buttonsIsShown, isDeleteButtonOnly] = useMemo(() => {
            const isDeleteOnly = type === "Инвентаризация" && advanceReport.status === "waiting" || advanceReport.status === "delivery";
            return [(advanceReport.status === "pending" || advanceReport.status === "delivery" || isDeleteOnly), isDeleteOnly];
        }, [toJS(advanceReport).status, type]);

        const disableCancelButton = useMemo(() => {
            const currentLocation = location.pathname.split('/').slice(-1)[0];
            return !toJS(advanceReport).is_unapprovable && currentLocation === 'invoice';
        }, [location, toJS(advanceReport)]);

        const isClosedDocument = useCallback(() => {
            const currentLocation = location.pathname.split('/').slice(-1)[0];
            const isCorrectLocation = currentLocation === 'advance' || currentLocation === 'invoice';
            const isCorrectDocumentParams = !!(toJS(advanceReport)?.guid_1c && toJS(advanceReport)?.status === 'approved');
            return isCorrectLocation && isCorrectDocumentParams;
        }, [location.pathname, advanceReport]);

        const distributeDocument = async () => {
            const currentLocation = location.pathname.split('/').slice(-1)[0];

            if (!isClosedDocument() || !toJS(advanceReport)?.id) return;

            const response = await fetchDistributeDocument(
                {id: toJS(advanceReport).id},
                currentLocation as DistributionDocumentType
            );

            if (!response) return;

            await fetchReport(currentLocation);
        };

        return (
            <>
                <div className="registry-document__link-container">
                    <div
                        className="registry-document__header-link"
                        onClick={() => {
                            navigate("/main/registry");
                        }}
                    >
                        <BackButton/>
                    </div>
                    <span>{type}</span>
                </div>
                <div className="registry-document__header-buttons">
                    {isClosedDocument() && <Button
                        variant={EButtonVariant.fillLightButton}
                        font={EFontVariant.body2Regular}
                        height={"32px"}
                        maxWidth={"184px"}
                        borderRadius={"10px"}
                        text="Распровести документ"
                        disabled={disableCancelButton}
                        onClick={distributeDocument}
                    />}
                    {buttonsIsShown && (
                        <>
                            <HeaderPendingStatusButtons
                                deleteModalIsActive={deleteModalIsActive}
                                setDeleteModalIsActive={setDeleteModalIsActive}
                                isDeleteOnly={isDeleteButtonOnly}
                                operationStatus={toJS(advanceReport).status}
                            />
                        </>
                    )}
                    {advanceReport.status === "deleted" && (
                        <div
                            className="registry-document__deleted"
                            data-title={
                                (draftComment.length > 1 && draftComment) ||
                                `Документ не может быть принят к учету по следующим причинам: ${advanceReport.comment}`
                            }
                        >
                            <DeletedIcon/>
                            Помечено на удаление
                        </div>
                    )}
                    {type === "Инвентаризация" && advanceReport.status === "waiting" && (
                        <p className={"new-inventory"}>
                            Новая заявка, ожидает заполнения со стороны ответственных
                        </p>
                    )}
                </div>
            </>
        );
    },
);

export default DocumentHeader;
