import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext, useMemo } from "react";
import cls from "./table-styles.module.scss";
import { ArrowDownIcon, MinusIcon } from "ui-new/icons/expand-icons";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { YearPlanningContext } from "..";
import { useStore } from "store";

const MONTHS_IN_YEAR = 12;

interface TableHeaderProps {}

const TableHeaderComponent: FC<TableHeaderProps> = () => {
  const {
    RootStore: {
      YearPlanningStore: { allCurrentArticlesWithItems },
    },
  } = useStore();

  const { expanded, setExpanded } = useContext(YearPlanningContext);

  const isAllCanExpandExpanded = useMemo(() => {
    return (
      Object.entries(toJS(expanded)).filter(
        ([key, value]) => value && allCurrentArticlesWithItems.includes(+key)
      ).length === allCurrentArticlesWithItems.length
    );
  }, [allCurrentArticlesWithItems, expanded]);

  const onToggleExpand = useCallback(() => {
    setExpanded({
      ...expanded,
      ...allCurrentArticlesWithItems.reduce(
        (acc, id) => ({ ...acc, [id as number]: !isAllCanExpandExpanded }),
        {}
      ),
    });
  }, [allCurrentArticlesWithItems, expanded, isAllCanExpandExpanded, setExpanded]);

  return (
    <thead className={cls["thead"]}>
      <tr className={cls["tr"]}>
        <th className={`${cls["th"]} ${cls["sticky"]} ${cls["left"]}`}>
          <div className={cls["expanded-title"]} onClick={onToggleExpand}>
            <div className={cls["expand-icon"]}>
              {isAllCanExpandExpanded ? <MinusIcon /> : <ArrowDownIcon />}
            </div>
            <div className={cls["title-percentage-container"]}>
              <span>Разделы</span>
            </div>
          </div>
        </th>
        <ThList />
        <th className={`${cls["th"]} ${cls["total"]}`}>Итого за год</th>
      </tr>
    </thead>
  );
};

const ThList = () => (
  <>
    {Array.from({ length: MONTHS_IN_YEAR }).map((_, i) => (
      <th className={cls["th"]} key={i}>
        {dayjs().month(i).format("MMMM")}
      </th>
    ))}
  </>
);

export const TableHeader = observer(TableHeaderComponent);
