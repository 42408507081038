type AnyObject = Record<string, any>;

export function flattenByKey<T extends AnyObject>(array: T[], key: keyof T): T[] {
  const result: T[] = [];

  function recurse(items: T[]) {
    for (const item of items) {
      result.push(item);
      if (Array.isArray(item[key])) {
        recurse(item[key] as T[]);
      }
    }
  }

  recurse(array);
  return result;
}

export function flattenMapByKey<T extends AnyObject, E>(
  array: T[],
  key: keyof T,
  mapFn: (item: T) => E
): E[] {
  const result: E[] = [];

  function recurse(items: T[]) {
    for (const item of items) {
      result.push(mapFn({ ...item }));
      if (Array.isArray(item[key])) {
        recurse(item[key] as T[]);
      }
    }
  }

  recurse(array);

  return result;
}
