import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import cls from "../../styles.module.scss";
import { useStore } from "store";
import { TransactionContext } from "../..";

interface DescriptionsProps {}

const DescriptionComponent: FC<DescriptionsProps> = () => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { transaction },
    },
  } = useStore();

  const { isCash } = useContext(TransactionContext);

  const title = isCash ? "Комментарий" : "Назначение платежа";

  if (!transaction) return null;

  return (
    <div className={cls["transaction-description"]}>
      <h3>{title}</h3>
      {!transaction.description || transaction.description.length === 0 ? (
        <p>Отсутствует</p>
      ) : (
        <p>{transaction.description}</p>
      )}
    </div>
  );
};

export const Description = observer(DescriptionComponent);
