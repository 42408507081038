import * as yup from "yup";
import { IYearPlanningArticle, YearPlanningArticleTypes } from "../types";
import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";

const numberValidation = yup
  .number()
  .max(999999999.99, ({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max}`)
  .min(0, ({ min }) => `${ValidationMessages.get(ValidationTypes.min)} ${min}`)
  .required()
  .when(["is_editable", "type"], (deps, schema) => {
    const isEditable = deps[0];
    const type = deps[1];
    if (!isEditable || type === YearPlanningArticleTypes.percent) return yup.number().nullable();
    return schema;
  });

const articleSchema: yup.ISchema<IYearPlanningArticle> = yup.object({
  id: yup.number().required(),
  type: yup
    .mixed<YearPlanningArticleTypes>()
    .oneOf(Object.values(YearPlanningArticleTypes), "Недопустимый тип")
    .required(),
  is_money: yup.boolean().required(),
  title: yup.string().required(),
  parent_id: yup.number().nullable().required(),
  percent: yup
    .number()
    .max(100, ({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max}%`)
    .min(0, ({ min }) => `${ValidationMessages.get(ValidationTypes.min)} ${min}`)
    .required()
    .when(["is_editable", "type"], (deps, schema) => {
      const isEditable = deps[0];
      const type = deps[1];
      if (!isEditable || type !== YearPlanningArticleTypes.percent) return yup.number().nullable();
      return schema;
    }),
  is_editable: yup.boolean().required(),
  items: yup
    .array()
    .of(yup.lazy(() => articleSchema))
    .required()
    .test("percent-sum", "Сумма процентов должна быть 100%", items => {
      if (items && items.some(i => i.type === "percent" && i.is_editable)) {
        const totalPercent = items.reduce(
          (sum, item) => (item.type === "percent" ? sum + item.percent : sum),
          0
        );
        return totalPercent === 100;
      }
      return true;
    }),
  results: yup.array().required(),
  jan: numberValidation,
  feb: numberValidation,
  mar: numberValidation,
  apr: numberValidation,
  may: numberValidation,
  jun: numberValidation,
  july: numberValidation,
  aug: numberValidation,
  sep: numberValidation,
  oct: numberValidation,
  nov: numberValidation,
  dec: numberValidation,
  total: numberValidation,
});

export const schema = yup.object({
  articles: yup.array<IYearPlanningArticle[]>().of(articleSchema).required(),
});
