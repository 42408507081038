import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import cls from "./table-styles.module.scss";
import { ArrowDownIcon, MinusIcon } from "ui-new/icons/expand-icons";
import {
  IYearPlanningArticle,
  YearPlanningArticleTypes,
  YearPlanningArticleTypesSymbol,
} from "../types";
import { useFieldArray, useFormContext } from "react-hook-form";
import { InputInTable, PercentageInputInTable } from "./input";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import { addSpaces } from "assets/utils";
import { YearPlanningContext } from "..";
import { useStore } from "store";

const MONTHS_IN_YEAR = 12;
const headArticles = {
  fontSize: "15px",
  fontWeight: 700,
  lineHeight: "16px",
};

interface RowProps {
  index: number;
  depth?: number;

  article: IYearPlanningArticle;
  parentName: string;
}

const RowComponent: FC<RowProps> = ({ article, depth = 0, index, parentName }) => {
  const {
    RootStore: {
      YearPlanningStore: { currentPeriodStatus },
    },
  } = useStore();

  const canEdit = useMemo(
    () => !["approved", null].includes(currentPeriodStatus),
    [currentPeriodStatus]
  );

  const { expanded, setRowIsExpanded } = useContext(YearPlanningContext);
  const [isExpanded, setIsExpanded] = useState(!!expanded[article.id]);
  const { title, items, is_editable, is_money, total, type } = article;

  const rowName = useMemo(() => `${parentName}.${index}`, [parentName, index]);

  const { control } = useFormContext();
  const { fields } = useFieldArray({ control, name: `${rowName}.items`, keyName: "_id" });

  const canExpand = useMemo(() => items.length > 0, [items]);

  const onExpand = useCallback(() => {
    if (canExpand) setRowIsExpanded(article.id, !isExpanded);
  }, [article.id, canExpand, isExpanded, setRowIsExpanded]);

  useEffect(() => {
    if (canExpand) setIsExpanded(!!expanded[article.id]);
  }, [article.id, canExpand, expanded]);

  const isCellsEditable = useMemo(
    () => canEdit && is_editable && type === YearPlanningArticleTypes.number,
    [canEdit, is_editable, type]
  );

  const endLineSymbol = useMemo(
    () =>
      is_money
        ? `, ${YearPlanningArticleTypesSymbol["number"]}`
        : (type === YearPlanningArticleTypes.percent ||
            type === YearPlanningArticleTypes.percent_in_field) &&
          `, ${YearPlanningArticleTypesSymbol["percent"]}`,
    [is_money, type]
  );

  const months = useMemo(() => {
    return Array.from(Array(MONTHS_IN_YEAR).keys()).map((k, i) => {
      let month = dayjs().locale(en).month(k).format("MMM").toLowerCase();
      if (month === "jul") month = "july";

      if (type === YearPlanningArticleTypes["info_title"]) {
        return <td className={`${cls["td"]}`}></td>;
      }

      if (isCellsEditable) {
        return (
          <td className={`${cls["td"]}`}>
            <InputInTable name={`${rowName}.${month}`} control={control} type="number" />
          </td>
        );
      }

      return (
        <td className={`${cls["td"]}`}>
          {is_money
            ? addSpaces((article[month as keyof IYearPlanningArticle] as number).toFixed(2))
            : (article[month as keyof IYearPlanningArticle] as number)}
        </td>
      );
    });
  }, [type, isCellsEditable, is_money, article, rowName, control]);

  const articlesData = useMemo(() => {
    if (!isExpanded) return null;

    return fields.map((item, i) => (
      <Row
        parentName={rowName + ".items"}
        index={i}
        key={item._id}
        article={item as never as IYearPlanningArticle}
        depth={depth + 1}
      />
    ));
  }, [isExpanded, fields, rowName, depth]);

  return (
    <>
      <tr
        className={`${cls["tr"]} ${(canExpand && depth === 1) || depth === 0 ? cls["bold"] : ""} ${
          depth === 0 && isExpanded ? cls["purple"] : ""
        } ${isCellsEditable ? cls["editable"] : ""}`}
      >
        <td
          className={`${cls["td"]} ${cls["sticky"]} ${cls["left"]}`}
          style={depth === 0 ? headArticles : {}}
          onClick={onExpand}
        >
          {canExpand ? (
            <div className={cls["expanded-title"]}>
              <div className={cls["expand-icon"]}>
                {isExpanded ? <MinusIcon /> : <ArrowDownIcon />}
              </div>
              <div className={cls["title-percentage-container"]}>
                <span>
                  {title}
                  {endLineSymbol}
                </span>
                {type === YearPlanningArticleTypes.percent && (
                  <PercentageInputInTable
                    name={`${rowName}.percent`}
                    control={control}
                    inputProps={{ disabled: !canEdit ? true : !is_editable }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className={cls["title-percentage-container"]}>
              <span style={{ marginLeft: "8.5px" }}>
                {title}
                {endLineSymbol}
              </span>
              {type === YearPlanningArticleTypes.percent && (
                <PercentageInputInTable
                  name={`${rowName}.percent`}
                  control={control}
                  inputProps={{ disabled: !canEdit ? true : !is_editable }}
                />
              )}
            </div>
          )}
        </td>
        {months}
        <td className={`${cls["td"]} ${cls["total"]}`}>
          {type === YearPlanningArticleTypes["info_title"]
            ? null
            : is_money
            ? addSpaces(total.toFixed(2))
            : total}
        </td>
      </tr>
      {articlesData}
    </>
  );
};

export const Row = observer(RowComponent);
