import { Filters } from "ui/filters";
import { useStore } from "store";
import { PeriodFilter } from "./period";
import { SearchInput } from "./search";
import { PayTypeFilter } from "./pay-type";
import { OperationTypeFilter } from "./operation-type";
import { ContragentsFilter } from "./contragents";
import { observer } from "mobx-react-lite";
import { MoneyTransactionTypeFilter } from "./money-transaction-type";
import { StatusFilter } from "./status";
import { useEffect } from "react";

const BankStatementsFiltersComponent = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: {
        isFilterDefault,
        resetFilters,
        fetchGetContragents,
        fetchGetOperationTypes,
        fetchGetPayTypes,
        fetchGetMoneyTransactionTypes,
        fetchGetMoneyTransactionStatuses,
        contragents,
        operationTypes,
        payTypes,
        moneyTransactionTypes,
        moneyTransactionStatuses,
      },
    },
  } = useStore();

  useEffect(() => {
    if (!contragents.length) fetchGetContragents();
    if (!operationTypes.length) fetchGetOperationTypes();
    if (!payTypes.length) fetchGetPayTypes();
    if (!moneyTransactionTypes.length) fetchGetMoneyTransactionTypes();
    if (!moneyTransactionStatuses.length) fetchGetMoneyTransactionStatuses();
  }, []);

  return (
    <div className="statements__underheader">
      <Filters
        haveResetButton={!isFilterDefault}
        isResetActive={!isFilterDefault}
        filtersReset={resetFilters}
      >
        <PeriodFilter />
        <ContragentsFilter />
        <PayTypeFilter />
        <OperationTypeFilter />
        <MoneyTransactionTypeFilter />
        <StatusFilter />
      </Filters>
      <SearchInput />
    </div>
  );
};

export const BankStatementsFilters = observer(BankStatementsFiltersComponent);
