import { observer } from "mobx-react-lite";
import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cls from "./styles.module.scss";
import { IncomePlanningYearHeader } from "./header";
import { IYearPlanningArticle, YearPlanningTypes } from "./types";
import { Table } from "./table/table";
import { useStore } from "store";
import { toJS } from "mobx";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./table/yup-schema";
import { WarningIcon } from "ui-new/icons/warning-icon";
import { showAlertPopup } from "ui/alert";
import { ShowAlertPopupType } from "utils/const/shared";

export const YearPlanningContext = createContext<{
  expanded: { [key: number]: boolean };
  setRowIsExpanded: (key: number, value: boolean) => void;
  setExpanded: (value: { [key: number]: boolean }) => void;
}>({ expanded: {}, setRowIsExpanded: () => {}, setExpanded: () => {} });

interface YearPlanningProps {}

const YearPlanningComponent: FC<YearPlanningProps> = () => {
  const {
    RootStore: {
      YearPlanningStore: {
        fetchGetYearPlanning,
        yearPlanningExpenses,
        yearPlanningRevenue,
        selectedYear,
        updateYearPlanningConfirm,
        approveYearPlanningConfirm,
        activeTab,
      },
    },
  } = useStore();

  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  const setRowIsExpanded = useCallback(
    (key: number, value: boolean) => setExpanded({ ...expanded, [key]: value }),
    [expanded]
  );

  const activeData = useMemo(
    () =>
      toJS(activeTab === YearPlanningTypes["revenue"] ? yearPlanningRevenue : yearPlanningExpenses),
    [activeTab, yearPlanningExpenses, yearPlanningRevenue]
  );

  useEffect(() => {
    if (activeTab === YearPlanningTypes["revenue"] && yearPlanningRevenue.length > 0) return;
    if (activeTab === YearPlanningTypes["expenses"] && yearPlanningExpenses.length > 0) return;

    fetchGetYearPlanning(YearPlanningTypes[activeTab] as keyof typeof YearPlanningTypes);
  }, [activeTab, fetchGetYearPlanning, selectedYear]);

  const form = useForm<{ articles: IYearPlanningArticle[] }>({
    defaultValues: {
      articles: activeData,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset({ articles: activeData });
  }, [activeData]);

  const onSave = useCallback(
    (values: { articles: IYearPlanningArticle[] }) =>
      updateYearPlanningConfirm(
        YearPlanningTypes[activeTab] as "expenses" | "revenue",
        values.articles
      ),
    [activeTab, updateYearPlanningConfirm]
  );

  const onApprove = useCallback(
    (values: { articles: IYearPlanningArticle[] }) => {
      const warningMessage = (
        <span className={cls["warning-message"]}>
          <WarningIcon fillColor="#EB5757" className={cls["warning-icon"]} />
          После утверждения редактирование плана будет недоступно.
        </span>
      );
      approveYearPlanningConfirm(
        YearPlanningTypes[activeTab] as "expenses" | "revenue",
        values.articles,
        [warningMessage]
      );
    },
    [activeTab, approveYearPlanningConfirm]
  );

  const onError = (
    errors: FieldErrors<{
      articles: IYearPlanningArticle[];
    }>
  ) => {
    console.log(errors);

    showAlertPopup(
      "Ошибка валидации.",
      "Некорректно заполненные поля выделены красным.",
      ShowAlertPopupType.ERROR
    );
  };

  return (
    <FormProvider {...form}>
      <div className={cls["wrapper"]}>
        <IncomePlanningYearHeader
          onSave={handleSubmit(onSave, onError)}
          onApprove={handleSubmit(onApprove, onError)}
        />
        <YearPlanningContext.Provider value={{ expanded, setRowIsExpanded, setExpanded }}>
          <div className={cls["content"]}>
            <Table />
          </div>
        </YearPlanningContext.Provider>
      </div>
    </FormProvider>
  );
};

export const YearPlanning = observer(YearPlanningComponent);
